import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const Select = (props) => {
  const { options, label, name, id, required } = props;

  const [value, setValue] = useState('');

  return (
    <Root>
      <select
        name={name}
        id={id}
        required={required}
        className={`
          form-input dark ${value !== '' && 'active'}`}
        onChange={(e) => setValue(e.target.value)}
      >
        <option value="" className={`form-label-large dark`}></option>
        {options.map((item, index) => (
          <option value={item.value} key={index} className={`form-input dark`}>
            {item.label}
          </option>
        ))}
      </select>
      <label
        htmlFor={id}
        className={`
          form-label dark ${value !== '' && 'active'}`}
      >
        {label}
      </label>
      <img
        src={require('src/assets/images/icons/chevron-dark.svg')}
        alt="Select"
      />
      <span />
    </Root>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  ${vw('width', 288, 688, 494)}
  ${vw('height', 44, 52, 52)}
  ${vw('margin-bottom', 40, 43, 43)}

  label {
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    left: 0;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
    &.form-label-small {
    }
  }
  img {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    ${vw('width', 14)}
  }

  select {
    width: 100%;
    border: none;
    background-color: transparent;
    appearance: none;
    ${vw('height', 20, 24)}
    ${vw('margin-bottom', 6, 7)}
    &:-webkit-autofill {
      appearance: none;
      box-shadow: 0 0 0px 1000px transparent inset;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  select:focus {
    background-color: transparent;
    outline: 0;
    border: none;
  }

  select:focus + label,
  select.active + label {
    top: 0;
    transform: translateY(0);
    ${vw('font-size', 9)}
    ${vw('line-height', 12)}
    ${vw('letter-spacing', 2.25)}
  }

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.darkBrown};
  }
`;

export default Select;
