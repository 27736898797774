module.exports = {
  defaultLocale: 'en',
  locales: ['en', 'tc'],
  prerender: true,
  dbProject: 'GH-HK',
  analytics: {
    gtag: '',
    gtmId: 'GTM-PZGJNJD',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Gryphon House',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
