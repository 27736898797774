import React from 'react';

export default {
  switchLang: '中文',
  comingSoon: 'coming soon',
  menuOpen: 'menu',
  menuClose: 'close',
  watchFilm: 'Watch Film',
  videoComingSoon: 'Full Film Coming Soon',
  menu: [
    {
      text: 'Philosophy',
      title: (
        <>
          Our Story
          <br />
          Beings with
          <br />
          <span>Art</span>
        </>
      ),
    },
    {
      text: 'Creation',
      title: (
        <>
          The Making
          <br />
          of a<br />
          <span>Masterpiece</span>
        </>
      ),
    },
    {
      text: 'Location',
      title: (
        <>
          The Timeless
          <br />
          Tale of
          <br />
          <span>Kerrisdale</span>
        </>
      ),
    },
    {
      text: 'Design',
      title: (
        <>
          Each Home,
          <br />A{' '}
          <span>
            Work of
            <br />
            Art
          </span>
        </>
      ),
    },
    {
      text: 'Floorplans',
      title: (
        <>
          Each Life, A
          <br />
          <span>
            Modern
            <br />
            Masterpiece
          </span>
        </>
      ),
    },
    {
      text: 'amenities',
      title: (
        <>
          Gryphon
          <br />
          House
          <br />
          <span className="left-padding">Lifestyle</span>
        </>
      ),
    },
    {
      text: 'Contact',
      title: (
        <>
          <span>Inquiries</span>
        </>
      ),
    },
    {
      text: 'Register',
      title: (
        <>
          <span>Register</span>
          <br />
          Your Interest
        </>
      ),
    },
  ],
  register: 'Register',
  home: {
    hero: {
      title: (
        <>
          Modern
          <br />
          <span>Grandeur.</span>
          <br />
          Greenside
          <br />
          <span>Manor.</span>
        </>
      ),
    },
    intro: {
      title: (
        <>
          A New Residential <span>Experience</span> in{' '}
          <span>Vancouver West</span>
        </>
      ),
      text: '2 & 3 Bedroom Homes from CAD $1,599,900',
    },
    linkBlock: {
      title: (
        <>
          Each Home, <br />
          <span>A Work of Art</span>
        </>
      ),
      body: 'As the legendary gryphon, half lion, half eagle, Gryphon Development delivers a global viewpoint on the very best in lifestyle and culture encompassing the world’s finest neighbourhoods.',
      button: 'See the Residences',
    },
    contentBlock: [
      {
        title: (
          <h2 className="dark">
            <span>Prestigious </span>
            Vancouver West Side
          </h2>
        ),
        body: (
          <>
            This is a place for living – a time-honoured way of life. The chosen
            location for Gryphon House. Wander tranquil streets of ancient elms
            and stately manses, the high street shopping district just around
            the corner. Boulevard greenway awaits by your window side.
          </>
        ),
      },
      {
        title: (
          <h2 className="dark">
            Exclusive <span>Private Club</span> Amenities &amp; Services
          </h2>
        ),
        body: (
          <>
            With careful deliberation and care, a curated experience not only of
            home, but way of life, is formed here, at Gryphon House. From
            private club amenities to Gryphon’s Royal Curator Program, your
            bespoke lifestyle is perfected.
          </>
        ),
      },
      {
        title: (
          <h2 className="dark">
            <span>Top Rated</span> School Network
          </h2>
        ),
        body: (
          <>
            In a neighbourhood renowned for the opportunities it presents, the
            city’s finest educational institutions are right at home. From elite
            private schools to many of the city’s leading public schools, to one
            of the world’s best universities; this is a place where lives are
            shaped, and futures are built.
          </>
        ),
      },
      {
        title: (
          <h2 className="dark">
            Global <span>Inspiration</span> and <span>Design</span>
          </h2>
        ),
        body: (
          <>
            Gryphon took flight to find inspiration in exceptional locations
            around the world, all to deliver a global viewpoint on the very
            best. Yamamoto Architecture infuses art and design into the city
            skyline; establishing a classic design sensibility, inspired by
            London’s finest homes. Notable Vancouver design team False Creek
            Design Group brings nearly three decades of innovation and
            experience to each element of Gryphon House, forming a personalized
            sense of grandeur.
          </>
        ),
      },
      {
        title: (
          <h2 className="dark">
            <span>A Modern Evolution</span> by The Greenway
          </h2>
        ),
        body: (
          <>
            Kerrisdale is now in the midst of a significant transformation, as
            one of the lengthiest linear parks in the world begins its modern
            evolution. Here, public plazas filled with artwork, spectacular
            gardens, cafés and bistros invite leisurely sophistication,
            meandering reflection, chance encounters, and moments of
            inspiration.
          </>
        ),
      },
    ],
  },
  philosophy: {
    subheader: 'Philosophy',
    header: (
      <>
        Our Story
        <br />
        Begins with
        <br />
        <span>Art</span>
      </>
    ),
    art: {
      subTitle: 'The Art of Every Day',
      title: (
        <>
          For art to make a <span>significant impact</span> on the way we shape
          community, we must first <span>re-imagine its role</span> in the way
          we craft our homes.
        </>
      ),
      text: (
        <>
          Gryphon has created <span>three divisions</span> to deepen our focus
          on the art of living, exploring how we can immerse ourselves in a
          world of art, open our minds to the insights and perspectives of
          creators and thinkers around the world, and curate our experiences of
          each day.
        </>
      ),
    },
    linkBlock: {
      title: (
        <>
          The Making of a <span>Masterpiece</span>
        </>
      ),
      body: 'With a globally inspired, collaborative approach to the art of living, Gryphon House becomes the brilliant realization of Kerrisdale’s prestigious evolution.',
      button: 'See the Creation',
    },
    contentBlock: [
      {
        title: (
          <h3>
            The Gryphon
            <br />
            <span>Philosophy</span>
          </h3>
        ),
        body: (
          <>
            Our story begins with art. For twenty-five years, our parent
            company, Jinray, changed the way we conceive of architecture.
            <br />
            <br />
            Jinray insisted on artistry in every element; offering true luxury
            with a creative mastery, changing both skylines and lifestyles in
            unexpected ways across Taiwan. Artists were invited to lead the
            conversation. Towers arose as new forms of art.
          </>
        ),
        images: [
          'philosophy/wooden-thin-panels.jpg',
          'philosophy/jinray-eurostar.jpg',
          'philosophy/jinray-artfair.jpg',
          'philosophy/jinray-resort.jpg',
        ],
        settings: {
          template: 2,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            On the <span>Wings</span>
            <br />
            of Gryphon
          </h3>
        ),
        body: (
          <>
            The powerful lion representing Jinray metamorphosed as the company
            took flight to traverse the globe and establish itself in an
            exceptional new location – that would become all the more
            extraordinary for future generations.
            <br />
            <br />
            As the legendary gryphon, half lion, half eagle, Gryphon Development
            delivers a global viewpoint on the very best in lifestyle and
            culture encompassing the world’s finest neighbourhoods – the Upper
            East Side, Chelsea, Mayfair. Kerrisdale.
          </>
        ),
        images: [
          'philosophy/wooden-wing-panels.jpg',
          'philosophy/westbury-living.jpg',
          'philosophy/musee-sculpture-lady.jpg',
          'philosophy/westbury-rooftop.jpg',
        ],
        // video: {
        //   label: 'Watch Corporate Video',
        //   file: '',
        // },
        settings: {
          template: 3,
          rowReverse: false,
        },
      },
    ],
    cards: [
      {
        logo: 'logo-atelier-white.png',
        logoSize: 2,
        body: 'Our collaborative, artist-driven approach to real estate development. We harness the creative potential of a wide spectrum of world-class artists – their unique perspective and backgrounds – to create new living art forms. ',
        background: 'atelier-background.jpg',
      },
      {
        logo: 'logo-royal-curator-white.png',
        logoSize: 0,
        body: 'Our latest manifestation of the art of living, in which your every need is attended to by a team of committed professionals dedicated to giving you the one thing that everyone desires more of: time. ',
        background: 'curator-background.jpg',
      },
      {
        logo: 'logo-musee-white.png',
        logoSize: 1,
        body: 'A platform and gallery space that celebrates contemporary artists and their respective work.',
        background: 'musee-background.jpg',
      },
    ],
  },
  creation: {
    subheader: 'Creation',
    header: (
      <>
        The Making
        <br />
        of a<br />
        <span>Masterpiece</span>
      </>
    ),
    atelier: {
      subTitle: 'ATELIER GRYPHON',
      title: (
        <>
          We harness the creative potential of a wide spectrum of{' '}
          <span>world-class artists</span> – to create{' '}
          <span>new living art forms.</span>
        </>
      ),
      block1: {
        title: 'Yamamoto Architecture',
        text: 'Eminent architect and renowned sketch artist Taizo Yamamoto brings his artist’s eye to Atelier Gryphon, infusing both art and design into our thinking, our vision, and our city’s skyline.',
      },
      block2: {
        title: 'False Creek Design Group',
        text: 'With nearly three decades of experience, False Creek Design Group informs our understanding of the beauty of function, and the complexity of good design from the inside out.',
      },
    },
    collage: {
      title: (
        <>
          Global <span>Sophistication, </span>
          <br />
          Set in Stone
        </>
      ),
      text: 'British-inspired exterior cladding of European limestone creates a worldly sense of sophistication for the future of Kerrisdale. This distinguished stone is famed for its use in world monuments including Westminster Abbey, Buckingham Palace, and St Paul’s Cathedral. A historic elegance befitting an iconic presence, right here in Kerrisdale.',
    },
    contentBlock: [
      {
        title: (
          <h3>
            A Global
            <br />
            <span>Perspective</span>
          </h3>
        ),
        body: (
          <>
            From the world-class innovation of our parent company, Jinray, in
            Taiwan, to the unique collaboration of the Atelier Gryphon, to our
            travels across the globe in pursuit of new forms of inspiration,
            Gryphon seeks the finest of art, architecture, and lifestyle – all
            to create a globally informed way of life, right here in Kerrisdale.
          </>
        ),
        images: [
          'creation/west-blvd-aerial.jpg',
          'creation/paris-aerial.jpg',
          'creation/london-apartment-facade.jpg',
          'creation/london-bank-district.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: true,
        },
      },
      {
        subtitle: 'Architecture',
        title: (
          <h3>
            Each home,
            <br />a <span>work of art.</span>
          </h3>
        ),
        body: (
          <>
            Inspired by the some of the finest examples of modern traditionalism
            in London, Gryphon House brings a transformative excellence to
            Kerrisdale.
            <br />
            <br />
            Yamamoto creates a classic design sensibility to complement the
            historic nature of the surrounding neighbourhood, while offering a
            new perspective to illustrate the future of a neighbourhood – and a
            new art form for the city.
          </>
        ),
        images: ['creation/taizo-yamamoto-architecture-plans.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: 'Interior design',
        title: (
          <h3>
            Each life, a modern
            <br /> <span>masterpiece.</span>
          </h3>
        ),
        body: (
          <>
            False Creek Design Group brings a quiet splendor to each element of
            Gryphon House. With inventive details, high design, and a level of
            finish rarely found anywhere in the world, Gryphon House becomes the
            brilliant realization of Kerrisdale’s prestigious evolution.
          </>
        ),
        images: [
          'creation/coffee-tables.jpg',
          'creation/fcdg-tim.jpg',
          'creation/fcdg-dale.jpg',
        ],
        settings: {
          template: 5,
          rowReverse: true,
        },
      },
    ],
    linkBlock: {
      title: (
        <>
          The Timeless
          <br /> Tale of
          <br /> <span>Kerrisdale</span>
        </>
      ),
      text: 'Long established. Forever revered. The art of living well begins with location, in the enduring heart of Vancouver’s prestigious west side – where the transformative power of global inspiration brings new sophistication to a time-honoured way of life.',
      buttonLabel: 'Discover the Location',
    },
  },
  location: {
    subheader: 'Location',
    header: (
      <>
        The Timeless
        <br />
        Tale of
        <br />
        <span>Kerrisdale</span>
      </>
    ),
    map: {
      title: (
        <>
          Vancouver’s
          <br />
          <span>West Side</span>
        </>
      ),
      text: 'Chosen with care, situated with purpose, founded for a life of convenience and enjoyment. This is the art of location.',
      categories: [
        {
          title: 'high street shopping',
          points: [
            {
              text: 'Kerrisdale High Street',
              color: '#ed6f33',
            },
            {
              text: 'West Boulevard Shopping Corridor',
              color: '#3e63ae',
            },
          ],
        },
        {
          title: 'Enjoyment & refinement',
          points: [
            {
              text: `Golfer's Paradise`,
              letter: 'A',
            },
            {
              text: 'Southlands Riding Club',
              letter: 'B',
            },
            {
              text: 'Arbutus Club',
              letter: 'C',
            },
            {
              text: 'Kerrisdale Arena',
              letter: 'D',
            },
            {
              text: 'Kerrisdale Community Centre',
              letter: 'E',
            },
          ],
        },
        {
          title: 'space & fresh air',
          points: [
            {
              text: 'Elm Park',
              letter: 'A',
            },
            {
              text: 'Maple Grove Park',
              letter: 'B',
            },
            {
              text: 'Kerrisdale Park',
              letter: 'C',
            },
            {
              text: 'Arbutus Greenway',
              color: '#407d12',
            },
          ],
        },
        {
          title: 'Education',
          points: [
            {
              text: 'Maple Grove & Magee Secondary',
              letter: 'A',
            },
            {
              text: 'Point Grey Secondary',
              letter: 'B',
            },
            {
              text: 'Crofton House',
              letter: 'C',
            },
            {
              text: 'St. George’s Junior School',
              letter: 'D',
            },
            {
              text: 'St. George’s Senior School',
              letter: 'E',
            },
            {
              text: 'Vancouver College',
              letter: 'F',
            },
            {
              text: 'University of British Columbia',
              letter: 'G',
            },
          ],
        },
      ],
    },
    contentBlock: [
      {
        title: (
          <h3>
            This is a place for
            <br />
            living—<span>a time</span>—
            <br />
            <span>honoured</span> way of life.
          </h3>
        ),
        body: (
          <>
            Big manses, rolling lawns, and generations of growth and becoming
            flavour Kerrisdale’s unmistakable aura of prestige.
            <br />
            <br />
            It’s a rare position in a revered westside neighbourhood. The finest
            schools in the region are right here. Cafés and restaurants,
            clothiers and bookshops coalesce to form a pocket of high culture in
            the heart of the city – and cater to the next chapter of urban life.
          </>
        ),
        images: [
          'location/walking-with-horse.jpg',
          'home/thomas-hobbs-florist.jpg',
          'location/kerrisdale-clock.jpg',
          'location/bike-at-storefront.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            Distinguished
            <br />
            by Academic
            <br />
            <span>Excellence</span>
          </h3>
        ),
        body: (
          <>
            From elite private schools to the city’s leading public schools, to
            one of the world’s best universities; this is a place where lives
            are shaped, and futures are built.
          </>
        ),
        images: [
          'location/crofton-house-school.jpg',
          'location/school-stone-facade.jpg',
          'location/magee-secondary-school.jpg',
          'location/students-in-uniform.jpg',
        ],
        settings: {
          template: 1,
          rowReverse: false,
        },
      },
      {
        title: (
          <h2>
            A World-Class Public
            <br /> <span>Promenade</span>
          </h2>
        ),
        subtitle: 'the arbutus greenway',
        body: (
          <>
            Just across West Boulevard from Gryphon House, one of the lengthiest
            linear parks in the world becomes a corridor of art installations,
            cafés and kiosks, community spaces, and breathtaking landscape
            design. <br />
            <br />
            The transformation of a public promenade reminiscent of those found
            in the world’s most sophisticated urban centres – at your doorstep.
          </>
        ),
        images: [
          'location/west-blvd-aerial-closeup.jpg',
          'location/newyork-highline.jpg',
          'location/barcelona-las-ramblas.jpg',
          'location/paris-champs-elysees.jpg',
        ],
        settings: {
          template: 4,
          rowReverse: true,
        },
      },
    ],
    callouts: [
      {
        text: 'The exemplary New York Highline.',
      },
      {
        text: 'Avenue Montaigne, in the evocative heart of Paris.',
      },
      {
        text: 'Barcelona’s lively Las Ramblas.',
      },
      {
        text: 'The evolution of the boulevard parkway, on the threshold of Gryphon House.',
      },
    ],
    linkBlock: {
      title: (
        <>
          Each Home,
          <br />A <span>Work of Art</span>
        </>
      ),
      body: 'As the legendary gryphon, half lion, half eagle, Gryphon Development delivers a global viewpoint on the very best in lifestyle and culture encompassing the world’s finest neighbourhoods.',
      button: 'See the Design',
    },
  },
  contact: {
    subheader: 'Contact',
    header: (
      <>
        <span>Inquiries</span>
      </>
    ),
    directions: 'Get Directions',
    vancouver: {
      title: 'vancouver sales centre',
    },
    hk: {
      title: 'HONG KONG office',
    },
  },
  footer: {
    register: {
      subheader: 'inquiries',
      header: (
        <>
          Discover the <br />
          <span>Gryphon House </span>
          <br />
          way of life.
        </>
      ),
      button: 'register now',
    },
    contact: {
      contact: 'contact',
      social: 'social',
      disclaimer:
        'This is not an offer for sale and is for information purposes only. An offer for sale may only be made in conjunction with a Disclosure Statement. E. & O.E. The above images or drawings are for illustration purposes only. The developer reserves the right to alter size, price, design and/or finishings at any time.',
    },
  },
  registerPage: {
    intro: {
      subTitle: 'Register',
      title: (
        <>
          Discover the
          <br />
          <span>Gryphon House</span>
          <br />
          way of life.
        </>
      ),
      event: (
        <>
          <p>
            <ul>
              <li>2 Bedrooms starting at CAD $ 1,504,900</li>
              <li>3 Bedrooms starting at CAD $ 2,139,400</li>
              <li>4 Bedrooms starting at CAD $ 3,658,800</li>
              <li>
                Selected home with oversized expansive patio and 2 level
                townhomes with private garden
              </li>
              <li>Estimated completion summer 2024</li>
            </ul>
            <br />
            Register For This Weekend’s Sales Exhibition
            <br />
            Address: 4/F, Lee Garden 3, Causeway Bay, Hong Kong
            <br />
            Telephone: <a href="tel:+852-2156-9111">+852-2156-9111</a>
          </p>
        </>
      ),
    },
    firstName: 'First Name*',
    lastName: 'Last Name*',
    email: 'Email*',
    phone: 'Phone *',
    postal: 'postal code*',
    howHeard: 'how did you hear about us?',
    howHeardOptions: [
      {
        label: 'Signage',
        value: 'Signage',
      },
      {
        label: 'Walk/Drive-by',
        value: 'Walk/Drive-by',
      },
      {
        label: 'Online Advertising',
        value: 'Online Advertising',
      },
      {
        label: 'Print Advertising',
        value: 'Print Advertising',
      },
      {
        label: 'Family/Friend',
        value: 'Family/Friend',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    realtor: 'are you a realtor?*',
    realtorOptions: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
    typeHome: 'Type of Home',
    typeHomeOptions: [
      {
        label: '2 Bedroom +',
        value: '2 Bedroom +',
      },
      {
        label: '3 Bedroom +',
        value: '3 Bedroom +',
      },
      {
        label: 'Townhome',
        value: 'Townhome',
      },
      {
        label: 'Penthouse',
        value: 'Penthouse',
      },
      {
        label: 'Retail (CRU)',
        value: 'Retail (CRU)',
      },
    ],
    checkbox:
      'All information is used for registration purposes only, and the company will not disclose your personal information to any third party without your consent.',
    submit: 'Submit',
    register: 'Register',
    thankYou:
      'Thank you for registering for Gryphon House. A representative will be in touch with you shortly.',
    backToHome: 'Back to Home',
  },
  thankyou: {
    title: (
      <>
        Thank you for registering for <span>Gryphon House.</span>
      </>
    ),
    text: (
      <>
        Your registration has been received and a representative will be in
        touch with you shortly.
        <br />
        <br />
        If you would like to book an appointment, please follow the link below.
      </>
    ),
    button: 'back to homepage',
    appButton: 'book appointment',
  },
  appointment: {
    subtitle: 'book an appointment',
    title: (
      <>
        Schedule a private meeting to learn more about{' '}
        <span>Gryphon House.</span>
      </>
    ),
    office: 'Office:',
    text: '4/F, Lee Garden 3, Causeway Bay, Hong Kong',
  },
  residences: {
    hero: {
      subTitle: 'FLOORPLANS',
      title: (
        <>
          Each Life, A
          <br />
          <span>
            Modern
            <br />
            Masterpiece
          </span>
        </>
      ),
    },
    plans: {
      title: 'SAMPLE FLOORPLANS',
      filters: [
        {
          mobile: '2 BED',
          text: '2 bedroom',
          plans: ['B5c', 'B8', 'C1b', 'C2a'],
        },
        {
          mobile: '3 bed',
          text: '3 bedroom',
          plans: ['B1a', '507+508'],
        },
        {
          mobile: 'townhome',
          text: 'townhome',
          plans: ['TH4'],
        },
      ],
      button: 'sample floorplans pdf',
    },
    kitchen: {
      subTitle: 'Kitchen',
      title: (
        <>
          <span>State-of-the-art</span> appliances package
        </>
      ),
      text: 'Custom-crafted European cabinetry showcases an artisan’s skilled mastery, while stunning countertops of sparkling quartz form the centrepiece of the room.',
    },
    ensuites: {
      subTitle: 'Ensuites',
      title: (
        <>
          Simple <span>elegance</span> with British <span>sophistication</span>
        </>
      ),
      text: 'Clean lines, subtle hues, and floor-to-ceiling large-format tiles create the simple elegance of personal space with an enduring British sophistication, and all the grandeur of elevated contemporary design.',
    },
    features: 'download full features list',
    linkBlock: {
      title: (
        <>
          Gryphon House <span className="linkblock-title">Living</span>
        </>
      ),
      text: 'Gryphon House presents a life surrounded by beauty – with a rich abundance of lifestyle services and amenities designed for ease, wellness, and joy in each day.',
      button: 'SEE THE AMENITIES',
    },
  },
  amenities: {
    hero: {
      header: (
        <>
          Gryphon House
          <br />
          <span>Lifestyle</span>
        </>
      ),
      subheader: 'Amenities',
    },
    contentBlocks: [
      {
        title: (
          <h3>
            Crafting a<br />
            <span>Way of Life</span>
          </h3>
        ),
        body: (
          <>
            A lifestyle is the summation of details. In rare circumstances, we
            have the privilege and honour of selecting these details with
            exceptional care to craft a way of life. <br />
            <br />
            Gryphon House presents a life surrounded by beauty – with a rich
            abundance of lifestyle services and amenities designed for ease,
            wellness, and joy in each day.
          </>
        ),
        images: [
          'location/west-blvd-aerial-closeup.jpg',
          'amenities/yoga-mat.jpg',
          'amenities/preparing-drink.jpg',
          'amenities/butler-with-champagne.jpg',
        ],
        settings: {
          template: 4,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            Your Life,
            <br />
            <span>Perfected</span>
          </h3>
        ),
        body: (
          <>
            Available exclusively to Gryphon Development Homeowners, the Royal
            Curator program is our latest manifestation of the art of living.
            With personal, anticipatory care, the Royal Curator program is
            designed to enhance your way of life – from the artistry with which
            you live it, to the time you have free to enjoy it.
          </>
        ),
        images: [
          'amenities/woman-beside-pool.jpg',
          'amenities/butler-with-ipad.jpg',
          'amenities/blue-suit.jpg',
          'amenities/butler-opening-cardoor.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: false,
        },
        buttonLabel: 'view website',
      },
    ],
    callouts: [
      {
        title: 'concierge',
        text: 'Twenty-four hours a day, your Gryphon House Concierge brings a uniquely elevated ease to daily life, with porter service, parcel delivery and cold storage, and attentive service at all times.',
      },
      {
        title: 'SPA',
        text: 'Spa, massage, and infrared sauna. Personal care, body and soul.',
      },
      {
        title: 'gym',
        text: 'A capacious two-level fitness studio with state-of-the-art Technogym equipment and spin cycles, for daily wellness, right at home.',
      },
      {
        title: 'club lounge',
        text: 'The sumptuous Club Lounge and Sommelier Bar – the time to yourself, the details of design, the artistry of how we live.',
      },
      {
        title: 'executive meeting room',
        text: 'A stunning extension of your home, for a new business world.',
      },
      {
        title: 'Car Park Foyer',
        text: 'The unexpected magnificence of this oft-visited space – a defining moment in the artful appreciation of lifestyle.',
      },
    ],
    titleBlock: {
      subtitle: 'royal curator',
      title: (
        <>
          Your <span>every need</span> attended to – giving you the one thing
          everyone desires more of: <span>time.</span>
        </>
      ),
    },
    linkBlock: {
      title: (
        <>
          Our Story Begins
          <br />
          with <span>Art</span>
        </>
      ),
      text: 'As the legendary gryphon, half lion, half eagle, Gryphon Development delivers a global viewpoint on the very best in lifestyle and culture encompassing the world’s finest neighbourhoods',
      button: 'our philosophy',
    },
  },
  design: {
    hero: {
      header: (
        <>
          Each Home,
          <br />A <span>Work of Art</span>
        </>
      ),
      subheader: 'Design',
    },
    downloadFeatures: 'download full features list',
    contentBlocks: [
      {
        title: (
          <h3>
            Modern <span>Grandeur.</span>
            <br />
            Greenside <span>Manor.</span>
          </h3>
        ),
        text: 'Inspired by the some of the finest examples of contemporary traditionalism in London, Gryphon House brings a modern sense of timeless grandeur to Kerrisdale. These are homes alive with creativity, imbued with meaning, and built for stately in-town living.',
      },
      {
        title: (
          <h3>
            Inspired by
            <br />
            London’s <span>Finest</span>
          </h3>
        ),
        text: 'A significant presence engaging a full city block of these prestigious streets, Gryphon House is inspired by the infamous hotels of London, England, where modern design is deeply respectful of traditional architecture.',
      },
    ],
    callouts: [
      {
        title: 'Contemporary Traditionalism',
        text: 'With the disciplined simplicity and elegant scale of British modern traditionalism, magnificent vertical windows sit in striking contrast to the building’s linear horizontal form; mirrored by contrasting steel against the natural textures of stone.',
      },
      {
        title: 'appliances',
        text: (
          <>
            Since 1683, the visionary design, unsurpassed quality, and skilled
            craftsmanship of <span>Gaggenau Hausgeräte</span> has cemented its
            global reputation of consummate distinction – making each kitchen a
            work of art.{' '}
          </>
        ),
      },
      {
        title: 'quartz Countertops',
        text: 'Stunning countertops of sparkling quartz form the centrepiece of the room – a jewel of sleek modernity, with the timeless materiality of stone.',
      },
      {
        title: 'bedroom',
        text: (
          <>
            A private sanctuary with enclosed balcony space* and triple-paned
            windows for privacy, peace, and repose.
            <br />
            <span className="small">* Select residences</span>
          </>
        ),
      },
      {
        title: 'closet',
        text: 'Beautifully efficient custom crafted closet space for simplicity of storage.',
      },
      {
        title: 'terraces',
        text: 'Expansive terraces open each home to fresh air and abundant natural light. Private rooftop patios with pre-installed gas bibs invite casual dining al fresco, with views over the city to the North Shore mountains and placid Pacific Ocean.',
      },
      {
        title: 'rooftop',
        text: 'The large, lush rooftop patio. Where evenings luxuriate in the warmth of a sunset sky.',
      },
    ],
    titleBlocks: [
      {
        title: (
          <>
            Global <span>Sophistication,</span> <br />
            Set in Stone
          </>
        ),
        subtitle: 'a material as old as time',
        text: 'A distinguished exterior of European limestone sets the tone for the future of Kerrisdale. Famed for its use in such globally significant monuments as Westminster Abbey and St Paul’s Cathedral, there is a historic eminence to this exquisite material as old as time – with an enduring grandeur for generations to come.',
      },
      {
        title: (
          <>
            The beauty of intelligent design, <span>artfully imagined</span> for
            uncompromised family living.
          </>
        ),
        subtitle: 'welcome home',
        text: 'Elegantly recessed millwork welcomes you home to a refined entry foyer. Classic wide-plank flooring and traditional base mouldings pair with contemporary fixtures and finishes, for a sophisticated modern outlook.',
      },
    ],
    collage: {
      title: (
        <>
          From the Palace of Versailles to the streets of Kerrisdale, the{' '}
          <span>craftsmanship of stone carving</span> has changed very little.
        </>
      ),
    },
    imageCompares: {
      calloutTap: '(Tap image to toggle colour scheme)',
      living: {
        title: 'living room',
        text: 'Wide-open space with simple lines; refinement inherent in materiality. A canvas for your personal expression.',
      },
      kitchen: {
        title: 'kitchen',
        text: (
          <>
            Austere linearity and the warmth of wood strike a timeless balance,
            with custom-crafted <span>European cabinetry</span> that showcases
            an artisan’s skilled mastery.
          </>
        ),
      },
      bathroom: {
        title: 'bathroom',
        text: (
          <>
            Floor-to-ceiling large-format tile with sleek <span>Grohe</span>{' '}
            fixtures and thoughtful lighting offer all the grandeur of elevated
            contemporary design.
          </>
        ),
      },
    },
    linkBlock: {
      title: (
        <>
          Each Life,
          <br />A{' '}
          <span>
            Modern
            <br /> Masterpiece
          </span>
        </>
      ),
      body: 'Open, spacious floorplans, intuitively designed for a way of life. Each home carefully orchestrated to support the rhythms of your days.',
      button: 'see floorplans',
    },
  },
};
