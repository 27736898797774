import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Page from 'components/Page';
import Form from './elements/Form';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Register = () => {
  return (
    <Intl>
      {(t) => {
        return (
          <Root>
            <Page hideRegister removeOverflow>
              <Section>
                <FormWrapper>
                  <TitleBlock>
                    <h4 className="subheader moss">
                      {t.registerPage.intro.subTitle}
                    </h4>
                    <h2 className="dark">{t.registerPage.intro.title}</h2>
                    <br />
                    <br />
                    {t.registerPage.intro.event}
                  </TitleBlock>
                  <Form />
                </FormWrapper>
                <Rendering
                  src={require('src/assets/images/register/gryphon-house-exterior-entrance.jpg')}
                  alt=""
                />
              </Section>
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Register;

const Root = styled.div``;

const TitleBlock = styled.div`
  position: relative;
  text-align: left;
  li,
  a {
    color: ${({ theme }) => theme.color.darkBrown};
  }
  h4 {
    ${vw('margin-bottom', 32, 80)}
  }
  h2 {
    span {
      font-style: italic;
    }
  }
  ul {
    ${vw('margin-left', 25)}
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;

    h2 {
      max-width: ${vwDesktop(805)};
    }
    h4 {
      margin-bottom: ${vwDesktop(80)};
    }
    ul {
      margin-left: ${vwDesktop(25)};
    }
  }
`;

const Section = styled.div`
  ${vw('margin-top', 60, 80)}
  ${vw('margin-right', 16, 40, 60)}
  ${vw('margin-bottom', 60, 80)}
  ${vw('margin-left', 16, 40, 164)}
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
`;

const Rendering = styled.img`
  display: none;
  @media ${media.desktop} {
    display: block;
    position: sticky;
    top: 10vh;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
    width: auto;
    height: 80vh;
    min-height: 500px;
    max-height: 900px;
  }
`;

const FormWrapper = styled.div`
  @media ${media.desktop} {
    max-width: ${vwDesktop(494)};
  }
`;
