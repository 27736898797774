import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsOpen } from 'src/redux/menu';

import Intl from 'components/Intl';
import Kerrisdale from 'components/logos/Kerrisdale';
import Logo from 'components/logos/Logo';
import Lines from 'components/Lines';
import GoldTitle from 'components/typography/GoldTitle';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  const dispatch = useDispatch();
  return (
    <Intl>
      {(t, { locale }) => (
        <Root className="mobile-menu">
          <Wrapper>
            <Menu onClick={() => dispatch(setIsOpen(true))}>
              <img
                src={require('src/assets/images/icons/icon-menu-taupe.svg')}
                alt="Menu"
              />
              <p className="menu-language light">{t.menuOpen}</p>
            </Menu>
            <Lang>
              <StyledLink
                to="/"
                className="menu-language light"
                active={locale === 'en'}
              >
                En
              </StyledLink>
              <StyledLink
                to="/tc"
                className="menu-language light"
                active={locale === 'tc'}
              >
                繁
              </StyledLink>
            </Lang>
            <Flex>
              <StyledLines left />
              <Logo fadeLogo />
              <StyledLines />
            </Flex>
            {/* <HoverOverlay isHero /> */}
            <Mobile>
              <GoldTitle text={t.home.hero.title} englishOnly />
            </Mobile>
            <Flex bottom>
              <StyledLines left />
              <div>
                <Kerrisdale margin line />
              </div>
              <StyledLines />
            </Flex>
          </Wrapper>
          <Background />
        </Root>
      )}
    </Intl>
  );
};

const FadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1.05);
  }
`;

const ScaleIn = keyframes`
  0% {
    opacity: 0;
    transform: scaleX(0.2);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
`;

const FadeInCss = css`
  animation-name: ${FadeIn};
  animation-duration: 5s;
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 1s;
`;

const ScaleInCss = css`
  animation-name: ${ScaleIn};
  animation-duration: 2.5s;
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
  transform-origin: ${(props) => (props.left ? 'right' : 'left')};
`;

const Root = styled.div`
  height: 100vh;
  width: 100%;
  padding-top: max(${vwMobile(45)}, 8vh);
  padding-bottom: max(${vwMobile(85)}, 15vh);
  position: relative;
  overflow: hidden;
  background-color: black;
  @media ${media.tablet} {
    padding-top: ${vwTablet(100)};
    padding-bottom: ${vwTablet(150)};
  }
  @media ${media.desktop} {
    margin-top: -200vh;
    margin-bottom: 100vh;
    padding: 0;
    position: relative;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.8)
    ),
    url(${require('src/assets/images/menu/hero-william-merritt-chase-painting.jpeg')});
  background-size: cover;
  background-position: center;
  opacity: 0;
  ${FadeInCss}
  z-index: 0;
  @media ${media.desktop} {
    opacity: 0;
  }
`;

const StyledLines = styled(Lines)`
  @media ${media.desktop} {
    margin-top: 25px;
    opacity: 0;
    ${ScaleInCss}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  @media ${media.desktop} {
    position: relative;
    z-index: 2;
    padding: ${vwDesktop(20)} ${vwDesktop(60)} ${vwDesktop(16)};
  }
`;

const DelayFade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const DelayFadeCss = css`
  animation-name: ${DelayFade};
  animation-duration: ${({ theme }) => theme.animation.time};
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 3s;
`;

const Menu = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
    align-items: center;
    position: absolute;
    top: ${vwDesktop(62)};
    left: ${vwDesktop(60)};
    z-index: 2;
    /* opacity: 0; */
    cursor: pointer;
    /* ${DelayFadeCss} */
    img {
      width: ${vwDesktop(16)};
      display: block;
      margin-right: ${vwDesktop(8)};
    }
  }
`;

const Lang = styled.div`
  display: none;
  @media ${media.desktop} {
    margin-top: 25px;
    display: flex;
    position: absolute;
    top: ${vwDesktop(50)};
    right: ${vwDesktop(60)};
    z-index: 2;
    /* opacity: 0;
    ${DelayFadeCss} */
  }
`;

const StyledLink = styled(Link)`
  margin-right: ${vwDesktop(12)};
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
  &:hover {
    opacity: 1;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const Flex = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: ${(props) => (props.desktop ? 'none' : 'flex')};
  @media ${media.desktop} {
    display: flex;
    flex-direction: row;
    align-items: ${(props) => (props.bottom ? 'flex-start' : 'center')};
    z-index: 1;
    position: relative;
  }
`;

const BlurFadeIn = keyframes`
  0% {
    opacity: 0;
    filter: blur(40px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
`;

const MobileFadeInCss = css`
  animation-name: ${BlurFadeIn};
  animation-duration: ${({ theme }) => theme.animation.time};
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-fill-mode: forwards;
  animation-delay: 1s;
`;

const Mobile = styled.div`
  opacity: 0;
  ${MobileFadeInCss}
`;

export default Hero;
