import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import RoundButton from 'components/typography/RoundButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Thankyou = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page hideRegister>
          <Root>
            <div>
              <h4 className="subheader moss">{t.register}</h4>
              <h3>{t.thankyou.title}</h3>
              <p>{t.thankyou.text}</p>
              <Wrapper>
                <RoundButton
                  to={`${getLocaleURL()}/appointment`}
                  text={t.thankyou.appButton}
                  transparent
                />
                <RoundButton
                  to={getLocaleURL()}
                  text={t.thankyou.button}
                  transparent
                />
              </Wrapper>
            </div>
            <Image
              src={require('src/assets/images/register/gryphon-house-exterior-entrance.jpg')}
              alt="Exterior Entrance"
            />
          </Root>
        </Page>
      )}
    </Intl>
  );
};

const Root = styled.div`
  min-height: 100vh;
  padding: ${vwMobile(40)} ${vwMobile(16)};
  h3 {
    margin: ${vwMobile(32)} 0 ${vwMobile(60)};
  }
  h3 span {
    font-style: italic;
  }
  p {
    margin-bottom: ${vwMobile(32)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(80)} ${vwTablet(40)};
    h3 {
      margin: ${vwTablet(40)} 0 ${vwTablet(80)};
    }
    p {
      margin-bottom: ${vwTablet(40)};
    }
    > div {
      max-width: ${vwTablet(390)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} ${vwDesktop(60)};
    display: flex;
    justify-content: space-between;
    h3 {
      margin: ${vwDesktop(80)} 0 ${vwDesktop(40)};
      max-width: ${vwDesktop(390)};
    }
    > div {
      margin-left: ${vwDesktop(104)};
    }
    p {
      margin-bottom: ${vwDesktop(40)};
      max-width: ${vwDesktop(390)};
    }
  }
`;

const Wrapper = styled.div`
  a:first-of-type {
    margin-bottom: ${vwMobile(20)};
    margin-right: ${vwMobile(20)};
  }
  @media ${media.tablet} {
    a:first-of-type {
      margin-bottom: ${vwTablet(20)};
      margin-right: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: row;
    a:first-of-type {
      margin-bottom: 0;
      margin-right: ${vwDesktop(20)};
    }
  }
`;

const Image = styled.img`
  display: none;
  @media ${media.desktop} {
    display: block;
    width: ${vwDesktop(422)};
    height: ${vwDesktop(614)};
    object-fit: cover;
    box-shadow: ${({ theme }) => theme.shadow};
  }
`;

export default Thankyou;
